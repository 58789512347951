<template>
	<div class="threeDchats">
		<el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" :fullscreen="fullscreen" @open="openIframe">
			<div slot="title" style="font-size: 14px;font-weight: 400;color: rgba(255, 255, 255, 0.65);line-height: 20px;">{{ row && row.root ? row.root : '整线' }}</div>
			<!-- <div v-if="showLoading" class="loading"></div> -->
			<iframe id="commandIframe" :src="url" width="100%"></iframe>
		</el-dialog>
	</div>
</template>

<script>
import configApi from '@config/configApi';
import caches from '@cache/cache.js';
export default {
	props: ['row'],
	watch: {
		row(val) {
			this.url = val.url;
		}
	},
	data() {
		return {
			dialogVisible: false,
			fullscreen: true,
			url: '',
			data: {},
			dantiPopStyle: {},
			CurrentDantiParam: {},
			imgUrl: '',
			showLoading: true
		};
	},
	methods: {
		toThreeD() {
			window.postMessage({
				data: {
					unitId: this.data.unitId,
					type: 0
				}
			});
		},
		openIframe(){
			let _this =this;
			this.$nextTick(()=>{
				var iframe = document.getElementById("commandIframe");
				if (iframe.attachEvent){ // 兼容IE写法
					iframe.attachEvent("onload", function(){
						// iframe加载完成后要进行的操作
						_this.showLoading = false;
					})
				} else {
					iframe.onload = function(){
						// iframe加载完成后要进行的操作
						_this.showLoading = false;
					}
				}

			})
		}
	},
	updated() {}
};
</script>

<style lang="scss">
.threeDchats {
	.loading {
		width: 40px;
		height: 40px;
		border: 4px solid #4a99f6;
		border-top-color: transparent;
		border-radius: 100%;

		animation: circle infinite 0.75s linear;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin:auto;
	}

	// 转转转动画
	@keyframes circle {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	//
	.el-dialog__title {
		color: #fff;
	}

	.el-dialog {
		overflow-y: hidden;
	}

	.el-dialog__header {
		background-color: #262f4c;
		padding: 10px 30px;
	}

	.el-dialog__body {
		padding: 0;
		border: none;
		background-color: #262f4c;
	}
	.el-dialog__headerbtn .el-dialog__close{
		color: #fff;
		font-size: 24px;
		margin-top: -20px;
	}

	#commandIframe {
		border: none;
		height: 100vh;
		min-width: 1440px;
	}

	.danti-pop {
		display: none;
		width: 250px;
		min-height: 70px;
		position: absolute;
		z-index: 1001;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.85);
		padding: 10px 20px 20px;
		background: url('~@assets/osImg/jkdp/dantiPopBg.png') no-repeat;
		background-size: 100% 100%;

		.danti-pop-title {
			margin: 0 -12px;
			padding-left: 8px;
			background: url('~@assets/osImg/jkdp/dantiPopTitleBg.png') no-repeat;
			background-size: 100% 100%;
			height: 28px;
			font-size: 12px;
			color: rgba(255, 255, 255, 0.85);
			line-height: 28px;
		}

		.danti-pop-context {
			font-size: 12px;
			line-height: 22px;
		}
	}
}
</style>
