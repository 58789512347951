<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<!-- <el-col :span="10">
						<el-form-item label="选择企业：">
							<el-select v-model="searchParm.entId" clearable :popper-append-to-body="false" placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col> -->
					<el-col :span="10">
						<el-row>
							<el-form-item label="报警时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.startTime" value-format="yyyy-MM-dd hh:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.endTime" value-format="yyyy-MM-dd hh:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>报警列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="unitId" align="center" label="单体id" show-overflow-tooltip></el-table-column> -->
							<el-table-column prop="unitName" align="center" label="单体名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="time" align="center" label="报警时间" show-overflow-tooltip></el-table-column>
							<el-table-column prop="entName" align="center" label="企业名称" show-overflow-tooltip></el-table-column>
							<!-- <el-table-column align="center" label="企业权限">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">
										<el-tag size="medium">{{ scope.isEffective == 0 ? '未开放' : '已开放' }}</el-tag>
									</div>
								</template>
							</el-table-column> -->
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)">查看</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
		<lookList :isShow.sync="showlookListDialog" :dialogObj="lookListObj"></lookList>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import lookList from './list.vue';
export default {
	components: {
		smCard,
		lookList
	},
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			ents: [],
			showlookListDialog: false,
			lookListObj: {}
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code == 1000) {
					this.ents = res.data.records;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		//展示数据
		list() {
			this.$get('/backend-api/type/alarm/list', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//查看
		look(row) {
			this.lookListObj= row;
			this.showlookListDialog = true;
		},

		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		}
	},
	mounted() {
		this.list();
		this.getEnts();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
		this.showlookListDialog = false;
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
